const lang = process.env.GATSBY_LANG || 'en'
export default lang

export const glossary_slug = {
  en: `glossary`,
  it: `glossario-finanza`,
}
export const search_title = {
  en: `Search | Moneyfarm`,
  it: `Cerca | Moneyfarm`,
}
export const search_description = {
  en: `Search for information about Moneyfarm products and services`,
  it: `Cerca informazioni sui prodotti e servizi offerti da Moneyfarm`,
}
export const expected_performance_title = {
  en: `Expected performance | Moneyfarm`,
  it: `Rendimenti attesi | Moneyfarm`,
}
export const expected_performance_description = {
  en: `Expected performance`,
  it: `Rendimenti attesi`,
}
export const back_to_index = {
  en: `Back to index`,
  it: `Ritorna all'indice`,
}
export const need_more_help = {
  en: `Need more help?`,
  it: `Serve aiuto?`,
}
export const contact_us = {
  en: `Contact us`,
  it: `Contattaci`,
}
export const sign_in = {
  en: `Sign in`,
  it: `Accedi`,
}
export const get_started = {
  en: `Get started`,
  it: `Inizia ora`,
}
export const scroll_hint = {
  en: `SEE MORE`,
  it: `SCOPRI DI PIÙ`,
}
export const faq_filter_input_placeholder = {
  en: `Type any keyword or question to filter the results`,
  it: `Digita un argomento o domanda per filtrare i risultati`,
}
export const faq_filter_no_matches_found = {
  en: `No matches found for this category. Please try a different one.`,
  it: `Nessun risultato trovato per questa categoria. Prova a selezionarne una diversa.`,
}
export const low_risk = {
  en: `Lower risk`,
  it: `Rischio più basso`,
}
export const high_risk = {
  en: `Higher risk`,
  it: `Rischio più alto`,
}
export const investment = {
  en: `Investment`,
  it: `Investimento`,
}
export const complexity_level_1 = {
  en: `Less than 50.000`,
  it: `Meno di 50,000`,
}
export const complexity_level_2 = {
  en: `More than 50.000`,
  it: `Più di 50,000`,
}
export const portfolio = {
  en: `Portfolio`,
  it: `Portafoglio`,
}
export const year = {
  en: `Year`,
  it: `Anno`,
}
export const performance = {
  en: `performance`,
  it: `Rendimento`,
}

export const esg_option = {
  en: 'ESG option',
  it: 'Opzione ESG',
}

export const performanceStartDate = {
  en: `Showing data from`,
  it: `Mostra dati dal`,
}
export const table_moneyfarm_performance_header = {
  en: `Moneyfarm`,
  it: `Moneyfarm`,
}
export const table_competitors_performance_header = {
  en: `Competitors`,
  it: `Concorrenti`,
}
export const composition = {
  en: `breakdown`,
  it: `Composizione`,
}
export const average_annual = {
  en: `Annual average`,
  it: `Media annua`,
}
export const all_time = {
  en: `All time`,
  it: `Dall'inizio`,
}
export const last_twelve_months = {
  en: `Last 12 months`,
  it: `Ultimi 12 mesi`,
}
export const year_to_date = {
  en: `Year to date`,
  it: `Da inizio anno`,
}
export const show_individual_years = {
  en: `Show individual years`,
  it: `Mostra gli anni individualmente`,
}
export const hide_individual_years = {
  en: 'Hide individual years',
  it: 'Nascondi gli anni',
}
export const perf_linechart_legend_moneyfarm = {
  en: 'Moneyfarm portfolio',
  it: 'Moneyfarm portafoglio',
}
export const perf_linechart_legend_benchmark = {
  en: "Competitors' equivalent",
  it: 'Portafoglio equivalente',
}
export const key_to_the_figures = {
  link: {
    en: 'Key to the figures',
    it: 'Maggiori informazioni',
  },
  title: {
    en: `Key to the figures`,
    it: `Maggiori informazioni`,
  },
  description: {
    en: `Moneyfarm returns net of fees since inception (01/01/2016) vs. average peer group performance over the same time period. These past performance figures are simulated. Past performance is no indicator of future performance. The allocations shown above are based on our model portfolios at a point in time, so they’re an illustration of how your actual portfolio might look. The exact composition may differ, if the value of your portfolio falls below c. £3,000.`,
    it: `I rendimenti mostrati sono una simulazione dei rendimenti dei nostri portafogli modello dal 30 dicembre 2011. La performance passata non è un indicatore affidabile dei rendimenti futuri.`,
  },
  full_info: {
    en: `Click here for full information`,
    it: `Clicca qui per ulteriori informazioni`,
  },
  full_info_paragraph: {
    en: `**Moneyfarm data**
                \n\nThe returns here are simulated using an assumed balance of £250,000, and the average management fee from our pricing model of 0.46% from 01/01/2016 to 31/10/2017 and 0.55% from 01/11/2017 to the 31/12/19. The returns are net of underlying fund costs and market spread. The returns are the total returns, so include all dividends. (Data Source: Bloomberg/xignite)
                \n\n**Peer Comparison Data**
                \n\nAsset Risk Consultants ARC collects the monthly returns of a collection of discretionary investment managers including large Asset managers, private banks and Wealth Managers to create an index of the average returns for a given level of risk.
                \n\n**ARC benchmarks are as follows**
                \n\n**Private Client Index Relative: Risk to World Equities**
                \nARC Cautious PCI: 0 – 40%
                \nARC Balanced Asset PCI: 40 – 60%
                \nARC Steady Growth PCI: 60 – 80%
                \nARC Equity Risk PCI: 80 – 110%
                \n\nWhere the relative risk to World Equities means you are taking approximately that percentage of the risk global stock markets.
                \nMoneyfarm’s Risk level 2 and 3 are compared to the ARC Cautious Private Client Index (PCI), our risk levels 4 uses the ARC Balanced Asset Private Client Index (PCI), levels 5 and 6 are both compared to ARC Steady Growth Private Client Index (PCI) and our risk level 7 is matched to ARC Equity Risk Private Client Index PCI. 
                \nARC compiles indexes using the average competitor returns, including Barclays Wealth, HSBC, Investec Wealth and Investment and Blackrock, as well as many others.
                \n\n**P7 Portfolio**
                \n\nPlease note that ARC does not independently verify the performance of the Model submitted by Moneyfarm. The returns shown refer to simulated past performance of our model portfolios since inception (01/01/2016), this portfolio only became available to clients on 16/05/2019. Past performance is not an indicator of future results.
                \n\n**P1 Portfolio**
                \n\nARC does not produce an index that is a suitable comparison for P1, as it does not contain any equity. We use monthly GBP Libor + 0.5%, as this is a cash proxy, which Moneyfarm believes is a fair comparison.
                \n\n**ESG Performance**
                \n\nPlease note that performance data until August 2021 is simulated based on the actual past performance of the underlying ETFs / indices, based on the initial composition of the ESG portfolios. Data is gross of fees, tax payable and shown in GBP and/or EUR. Data provided by Bloomberg. Past performance is not indicative of future results.`,
    it: `La simulazione si basa su un investimento iniziale senza successive contribuzioni né disinvestimenti. La performance è al lordo delle commissioni di gestione di Moneyfarm e delle tasse e al netto del costo degli strumenti.
                \n\nTi ricordiamo che tutti gli investimenti comportano un rischio. Per fare una scelta informata e capire come gestiamo il rischio relativo agli investimenti, consulta i [Documenti Informativi](/it/documenti-informativi) o contatta uno dei nostri consulenti.
                \n\n**Performance dei portafogli ESG**
                \n\nI dati sulle performance fino ad agosto 2021 sono simulati in base all'effettiva performance passata degli ETF/indici sottostanti, sulla base della composizione iniziale dei portafogli ESG. I dati sono al lordo delle commissioni e delle imposte dovute e sono espressi in EUR. Dati forniti da Bloomberg. Le performance passate non sono indicative dei risultati futuri.`,
  },
}

export const portfolio_asset_class_types = {
  assetClasses: {
    en: 'Type',
    it: 'Tipo',
  },
  sectorialAllocation: {
    en: 'Sector',
    it: 'Settori',
  },
  geographicalAllocation: {
    en: 'Geography',
    it: 'Geografico',
  },
}

export const portfolio_asset_classes = {
  // Asset classes
  'cash-and-short-term-government-bonds': {
    title: {
      en: 'Cash & short-term Gov. Bonds',
      it: 'Cash e bond governativi a breve termine',
    },
    description: {
      en: 'Includes short-term financial instruments. These investments are considered very low risk as they are easily tradable and have short time horizons. E.g. Sterling Cash Products.',
      it: 'Include strumenti finanziari a breve termine, in genere con scadenza non superiore ad un anno, molto liquidi e un livello di rischio basso, tipici per la gestione della liquidità. Alcuni esempi di titoli sottostanti gli ETF sono BOT italiani o T-Bills americani.',
    },
  },
  'developed-markets-government-bonds': {
    title: {
      en: `Developed Markets Gov. Bonds`,
      it: `Bond Governativi Paesi Sviluppati`,
    },
    description: {
      en: 'Includes bonds issued by developed countries such as the UK, US and Europe. These bonds are generally considered lower risk than other bond and equity investments.',
      it: 'Questa asset class rappresenta i bond governativi o dei paesi sviluppati a medio e lungo termine. Il profilo di rischio è stato storicamente basso anche se la situazione, per alcuni paesi, è cambiata significativamente negli scorsi anni. Il rischio è riconducibile alla capacità del paese di ripagare i titoli, definito come rischio di credito, e ai movimenti dei tassi di interesse della valuta di riferimento, rischio di tasso.',
    },
  },
  'investment-grade-corporate-bonds': {
    title: {
      en: 'Investment Grade Corporate Bonds',
      it: 'Bond Societari Investment Grade',
    },
    description: {
      en: 'These are bonds issued by companies with a strong credit rating. The rating is used to determine the risk profile of the company and Investment Grade bonds are considered the safest within the corporate space.',
      it: "Questa asset class rappresenta i bond societari emessi da istituti finanziari e con un rating considerato Investment Grade. Il rating serve a definire il profilo di rischio dell'emittente e questi emittenti godono di un rating considerato meno rischioso dalle agenzie. Il rischio è principalmente di credito cioè legato alla capacità dell'emittente di ripagare il titolo a scadenza.",
    },
  },
  'inflation-linked-bonds': {
    title: {
      en: 'Inflation Linked Bonds',
      it: 'Inflazione',
    },
    description: {
      en: `Developed countries bonds tied to inflation. They offer protection against inflation, their performance is linked to expected inflation rate changes and interest rate changes.`,
      it: `Questa asset class rappresenta le obbligazioni di paesi sviluppati che pagano interessi legati all'andamento dell'inflazione. Per tale ragione esse offrono protezione dall'erosione del valore della moneta, e il loro andamento è legato alle aspettative sul mutamento del tasso di inflazione. Il rischio è legato ai movimenti della curva dei tassi d'interesse e delle aspettative di inflazione.`,
    },
  },
  'high-yield-and-emerging-markets-bonds': {
    title: {
      en: 'High-Yield & Emerging Markets Bonds',
      it: 'Bond Societari HY & Bond Gov. EM',
    },
    description: {
      en: 'Includes government bonds of developing countries and riskier corporate bonds. These bonds tend to have lower credit ratings than Investment Grade Bonds but can offer higher potential returns.',
      it: 'Questa asset class rappresenta sia bond governativi o di imprese di paesi in via di sviluppo che bond societari dei paesi sviluppati ma classificati più rischiosi dalle agenzie di rating e definiti "High Yield" (alto rendimento). Il rischio è principalmente di credito cioè legato alla capacità dell\'emittente di ripagare il titolo a scadenza.',
    },
  },
  'developed-markets-equity': {
    title: {
      en: 'Developed Markets Equity',
      it: 'Azionario Paesi Sviluppati',
    },
    description: {
      en: 'Investments in the stock markets of developed countries, such as the UK, Europe and the US. Price changes are dependent on valuations, economic growth and investor risk aversion.',
      it: "Questa asset class rappresenta i mercati azionari dei paesi sviluppati, come ad esempio dell'area dell'Europa Occidentale e Nord America. Le variazioni di prezzo dipendono molto da mutamenti nei tassi di crescita dell'area di riferimento, dalla tipologia di azioni considerate e dal periodo storico o di mercato.",
    },
  },
  'emerging-markets-equity': {
    title: {
      en: 'Emerging Markets Equity',
      it: 'Azionario Paesi Emergenti',
    },
    description: {
      en: 'This asset class is the so-called developing markets, such as those in Asia, excluding Japan, or those of Latin America. The risk level is usually high and is linked to the ability of the different reference areas to deliver growth as expected. Usual returns are higher but more volatile than developed markets.',
      it: "Questa asset class rappresenta i mercati cosidetti in via di sviluppo, come ad esempio quelli asiatici, Giappone escluso, o quelli dell'America Latina. Il livello di rischio è generalmente elevato ed è legato alla capacità delle diverse aree di riferimento di tenere il passo della crescita attesa, ma a ciò corrispondono ritorni attesi maggiori.",
    },
  },
  'commodities-and-real-estate': {
    title: {
      en: 'Commodities and Real Estate',
      it: 'Materie prime e immobili',
    },
    description: {
      en: 'Commodities include products from energy, base material, agriculture and precious metals. The main risk is price fluctuations. Real estate includes investments in diversified baskets of properties, land or infrastructure.',
      it: "Questa asset class rappresenta le materie prime, che possono variare da energia, metalli preziosi, o prodotti dell'agricoltura e indici legati al mercato immobiliare. Queste due tipologie di investimenti sono molto legati all'andamento dell'economia reale.",
    },
  },
  cash: {
    title: {
      en: 'Cash',
      it: 'Liquidità',
    },
    description: {
      en: 'Cash is the amount of money that you need to leave in the account to deal with transactions, tax and management fees. Generally, it will be approximately 2-3% of your portfolio.',
      it: "La liquidità è la quota del tuo portafoglio che non viene impiegata in fondi e serve a garantire flessibilità all'allocazione durante i ribilanciamenti (oltre a fare fronte alla fiscalità e alla commissioni di gestione).",
    },
  },
  other: {
    title: {
      en: 'Other',
      it: 'Altro',
    },
    description: {
      en: 'This asset class includes ETFs that do not belong to any other asset class specific',
      it: 'Questa asset class contiene gli ETF che non appartengono a nessuna delle altre asset class specifiche',
    },
  },
  'multi-asset': {
    title: {
      en: 'Multi-Asset',
      it: 'Multi-Asset',
    },
    description: {
      en: 'A volatility-managed strategy which invests into multiple asset classes. This allows for micro adjustments of risk against your portfolio to ensure you always have a suitable investment.',
      it: 'Una strategia gestita in base alla volatilità che investe in più asset class. Questo permette di effettuare micro aggiustamenti del rischio del tuo portafoglio per garantire che tu abbia sempre un investimento adatto.',
    },
  },

  // geographical allocation
  china: {
    title: {
      en: 'China',
      it: 'Cina',
    },
    description: {
      en: 'China',
      it: 'Cina',
    },
  },
  'emerging-ex-china': {
    title: {
      en: 'Emerging markets (ex. China)',
      it: 'Mercati emergenti (esclusa la Cina)',
    },
    description: {
      en: 'Emerging markets (ex. China)',
      it: 'Mercati emergenti (esclusa la Cina)',
    },
  },
  eurozone: {
    title: {
      en: 'Eurozone',
      it: 'Eurozona',
    },
    description: {
      en: 'Eurozone',
      it: 'Eurozona',
    },
  },
  japan: {
    title: {
      en: 'Japan',
      it: 'Giappone',
    },
    description: {
      en: 'Japan',
      it: 'Giappone',
    },
  },
  'other-developed': {
    title: {
      en: 'Other developed markets',
      it: 'Altri mercati sviluppati',
    },
    description: {
      en: 'Other developed markets',
      it: 'Altri mercati sviluppati',
    },
  },
  'united-kingdom': {
    title: {
      en: 'United Kingdom',
      it: 'Regno Unito',
    },
    description: {
      en: 'United Kingdom',
      it: 'Regno Unito',
    },
  },
  'united-states': {
    title: {
      en: 'US',
      it: 'Stati Uniti',
    },
    description: {
      en: 'US',
      it: 'Stati Uniti',
    },
  },

  // sectorial allocation
  'non-energy-materials': {
    title: {
      en: 'Materials Sector',
      it: 'Settore dei materiali',
    },
    description: {
      en: 'The Materials Sector includes companies that manufacture chemicals, construction materials, glass, paper, forest products and related packaging products, and metals, minerals and mining companies, including producers of steel.',
      it: 'Il settore Materiali comprende le società che producono prodotti chimici, materiali da costruzione, vetro, carta, prodotti forestali e relativi imballaggi, e le società che producono metalli, minerali e miniere, compresi i produttori di acciaio.',
    },
  },
  telecommunications: {
    title: {
      en: 'Communication Services Sector',
      it: 'Settore dei servizi di comunicazione',
    },
    description: {
      en: 'The Communication Services Sector includes companies that facilitate communication and offer related content and information through various mediums. It includes telecom and media & entertainment companies including producers of interactive gaming products and companies engaged in content and information creation or distribution through proprietary platforms.',
      it: 'Il settore Servizi di comunicazione comprende le società che facilitano la comunicazione e offrono contenuti e informazioni correlati attraverso vari mezzi. Comprende le società di telecomunicazioni e di media e intrattenimento, compresi i produttori di giochi interattivi e le società impegnate nella creazione o distribuzione di contenuti e informazioni attraverso piattaforme proprietarie.',
    },
  },
  'consumer-cyclicals': {
    title: {
      en: 'Consumer Discretionary Sector',
      it: 'Settore dei beni di consumo',
    },
    description: {
      en: 'The Consumer Discretionary Sector encompasses those businesses that tend to be the most sensitive to economic cycles. Its manufacturing segment includes automotive, household durable goods, leisure equipment and textiles & apparel. The services segment includes hotels, restaurants and other leisure facilities, media production and services, and consumer retailing and services.',
      it: "Il settore Consumer Discretionary comprende le attività che tendono a essere più sensibili ai cicli economici. Il segmento manifatturiero comprende il settore automobilistico, i beni durevoli per la casa, le attrezzature per il tempo libero e il tessile e l'abbigliamento. Il segmento dei servizi comprende hotel, ristoranti e altre strutture per il tempo libero, produzione e servizi per i media e vendita al dettaglio e servizi per i consumatori.",
    },
  },
  'consumer-non-cyclicals': {
    title: {
      en: 'Consumer Staples Sector',
      it: 'Settore beni prima necessità',
    },
    description: {
      en: 'The Consumer Staples Sector comprises companies whose businesses are less sensitive to economic cycles. It includes manufacturers and distributors of food, beverages and tobacco and producers of non-durable household goods and personal products. It also includes food & drug retailing companies as well as hypermarkets and consumer super centers.',
      it: 'Il settore beni di prima necessità comprende società le cui attività sono meno sensibili ai cicli economici. Comprende produttori e distributori di alimenti, bevande e tabacco e produttori di beni non durevoli per la casa e prodotti personali. Include anche società di vendita al dettaglio di prodotti alimentari e farmaceutici, nonché ipermercati e centri di consumo.',
    },
  },
  energy: {
    title: {
      en: 'Energy Sector',
      it: 'Settore Energia ',
    },
    description: {
      en: 'The Energy Sector comprises companies engaged in exploration & production, refining & marketing, and storage & transportation of oil & gas and coal & consumable fuels. It also includes companies that offer oil & gas equipment and services.',
      it: "Il settore dell'energia comprende le aziende che si occupano di esplorazione e produzione, raffinazione e commercializzazione, stoccaggio e trasporto di petrolio e gas, carbone e combustibili di consumo. Comprende anche le aziende che offrono attrezzature e servizi per il settore petrolifero e del gas.",
    },
  },
  finance: {
    title: {
      en: 'Financials Sector',
      it: 'Settore Finanziario',
    },
    description: {
      en: 'The Financials Sector contains companies involved in banking, thrifts & mortgage finance, specialized finance, consumer finance, asset management and custody banks, investment banking and brokerage and insurance. It also includes Financial Exchanges & Data and Mortgage REITs.',
      it: "Il settore finanziario comprende le società che operano nei settori bancario, bancario e ipotecario, finanziario specializzato, finanziario al consumo, gestione patrimoniale e banche di deposito, banca d'investimento, brokeraggio e assicurazioni. Include anche le Borse e i dati finanziari e i REIT ipotecari.",
    },
  },
  industrials: {
    title: {
      en: 'Industrials Sector',
      it: 'Settore Industriali',
    },
    description: {
      en: 'The Industrials Sector includes manufacturers and distributors of capital goods such as aerospace & defense, building products, electrical equipment and machinery and companies that offer construction & engineering services. It also includes providers of commercial & professional services including printing, environmental and facilities services, office services & supplies, security & alarm services, human resource & employment services, research & consulting services. It also includes companies that provide transportation services.',
      it: "Il settore Industriali comprende i produttori e i distributori di beni strumentali come l'aerospaziale e la difesa, i prodotti per l'edilizia, le apparecchiature elettriche e i macchinari e le società che offrono servizi di costruzione e ingegneria. Include anche fornitori di servizi commerciali e professionali, tra cui servizi di stampa, ambientali e di struttura, servizi e forniture per ufficio, servizi di sicurezza e allarme, servizi per le risorse umane e l'occupazione, servizi di ricerca e consulenza. Include anche aziende che forniscono servizi di trasporto.",
    },
  },

  // This hasn't been translated as it's not used anywhere, for now.
  'non-corporate': {
    title: {
      // en: 'Government, mortgage and asset-backed securities',
      // it: 'Titoli di Stato, ipotecari e garantiti da attività',
      en: 'Non corporate',
      it: 'Settore non aziendale',
    },
    description: {
      en: '',
      it: '',
    },
  },

  technology: {
    title: {
      en: 'Information Technology Sector',
      it: "Settore Tecnologia dell'informazione",
    },
    description: {
      en: 'The Information Technology Sector comprises companies that offer software and information technology services, manufacturers and distributors of technology hardware & equipment such as communications equipment, cellular phones, computers & peripherals, electronic equipment and related instruments, and semiconductors.',
      it: "Il settore della tecnologia dell'informazione comprende aziende che offrono software e servizi informatici, produttori e distributori di hardware e attrezzature tecnologiche come apparecchiature di comunicazione, telefoni cellulari, computer e periferiche, apparecchiature elettroniche e relativi strumenti e semiconduttori.",
    },
  },
  utilities: {
    title: {
      en: 'Utilities Sector',
      it: 'Settore dei servizi di pubblica utilità',
    },
    description: {
      en: 'The Utilities Sector comprises utility companies such as electric, gas and water utilities. It also includes independent power producers & energy traders and companies that engage in generation and distribution of electricity using renewable sources.',
      it: "Il settore dei servizi di pubblica utilità comprende società di servizi come le società elettriche, del gas e dell'acqua. Include anche produttori indipendenti di energia e commercianti di energia e società che si occupano di generazione e distribuzione di elettricità da fonti rinnovabili.",
    },
  },
  sovereign: {
    title: { en: 'Sovereign', it: '' },
    description: { en: '', it: '' },
  },
  funds: { title: { en: 'Funds', it: '' }, description: { en: '', it: '' } },
  commodity: {
    title: { en: 'Commodity', it: '' },
    description: { en: '', it: '' },
  },
}

export const assets_breakdown = {
  title: {
    en: 'Assets breakdown',
    it: 'Composizione degli asset',
  },
}

export const data_loading_error = {
  en: `We are having trouble loading the data, please try again later.`,
  it: `Ci sono problemi con il caricamento dei dati, per favore riprova più tardi.`,
}

export const investor_profile_tabs = {
  tab_1: {
    tab: {
      en: `STEADY`,
      it: `STEADY`,
    },
    title: {
      en: `Steady`,
      it: `Steady`,
    },
    body: {
      en: `You need to know your investment is the safest it can be.`,
      it: `You need to know your investment is the safest it can be.`,
    },
  },
  tab_2: {
    tab: {
      en: `FOCUSED`,
      it: `FOCUSED`,
    },
    title: {
      en: `Focused`,
      it: `Focused`,
    },
    body: {
      en: `Knowing your investments are on track is key.`,
      it: `Knowing your investments are on track is key.`,
    },
  },
  tab_3: {
    tab: {
      en: `DRIVEN`,
      it: `DRIVEN`,
    },
    title: {
      en: `Driven`,
      it: `Driven`,
    },
    body: {
      en: `You've got a plan and want to find ways to make it happen.`,
      it: `You've got a plan and want to find ways to make it happen.`,
    },
  },
  tab_4: {
    tab: {
      en: `EXPLORING`,
      it: `EXPLORING`,
    },
    title: {
      en: `Exploring`,
      it: `Exploring`,
    },
    body: {
      en: `You're excited to find new opportunities for growth.`,
      it: `You're excited to find new opportunities for growth.`,
    },
  },
  tab_5: {
    tab: {
      en: `ADVENTUROUS`,
      it: `ADVENTUROUS`,
    },
    title: {
      en: `Adventurous`,
      it: `Adventurous`,
    },
    body: {
      en: `You're ambitious, without losing sight of the bigger picture.`,
      it: `You're ambitious, without losing sight of the bigger picture.`,
    },
  },
  tab_6: {
    tab: {
      en: `PIONEERING`,
      it: `PIONEERING`,
    },
    title: {
      en: `Pioneering`,
      it: `Pioneering`,
    },
    body: {
      en: `You've an eye on the future but your primary focus is high achievement.`,
      it: `You've an eye on the future but your primary focus is high achievement.`,
    },
  },
}

export const expected_performance = {
  initial_contribution: {
    en: `Initial deposit`,
    it: `Investimento iniziale`,
  },
  monthly_contribution: {
    en: `Monthly contribution`,
    it: `Contributo mensile`,
  },
  time_horizon: {
    en: `Time frame`,
    it: `Orizzonte temporale`,
  },
  years: {
    en: `years`,
    it: `anni`,
  },
  risk_level: {
    en: `Risk level`,
    it: `Livello di rischio`,
  },
  calculate: {
    en: `Calculate`,
    it: `Calcola`,
  },
  average_profit: {
    en: `Avg. Profit`,
    it: `Rendimento medio`,
  },
  projected_value: {
    en: `Avg. projected value`,
    it: `Valore atteso`,
  },
  contribution: {
    en: `Your contribution`,
    it: `Totale versato`,
  },
  more_likely: {
    en: `More likely`,
    it: `Più probabile`,
  },
  less_likely: {
    en: `Less likely`,
    it: `Meno probabile`,
  },
  year_by: {
    en: `By`,
    it: `Nel`,
  },
  minimumOneTimePersonalContributionAmount: {
    en: `The minimum investment required is £500.`,
    it: `L'investimento iniziale minimo è di 5.000€.`,
  },
  minimumMonthlyPersonalContributionAmount: {
    en: `The minimum monthly contribution is £100. Leave blank if you don’t wish to add one.`,
    it: `Il contributo mensile minimo è di 100€. Lascia il campo vuoto se non vuoi aggiungerne uno.`,
  },
}

export const minimumInvestmentAmount = {
  en: `The minimum investment is`,
  it: `L’investimento minimo è di`,
}

export const maximumInvestmentAmount = {
  en: `The maximum investment is`,
  it: `L’investimento massimo è di`,
}

export const pricingWidget = {
  investmentStyle: {
    tabClassic: {
      en: 'CLASSIC',
      it: 'CLASSICO',
    },
    tabEsg: {
      en: 'SOCIALLY RESPONSIBLE',
      it: 'SOCIALMENTE RESPONSABILE',
    },
  },
  allocationStrategy: {
    activelyManaged: {
      en: 'Actively managed',
      it: 'Gestito attivamente',
    },
  },
  yourFee: {
    en: 'Your fee',
    it: 'I tuoi costi',
  },
  tier: {
    en: 'Tier',
    it: 'Fascia',
  },
  from: {
    en: 'from',
    it: 'da',
  },
  perMonth: {
    en: 'per month',
    it: 'al mese',
  },
  investmentCosts: {
    en: 'Investment costs',
    it: 'Costo di investimento',
  },
  whatsIncluded: {
    en: "What's included",
    it: 'Cosa include',
  },
  upTo: {
    en: 'up to',
    it: 'in media',
  },
  features: {
    globallyDiversified: {
      en: 'Globally diversified',
      it: 'Globalmente diversificato',
    },
    dedicatedConsultant: {
      en: 'Dedicated consultant',
      it: 'Consulente dedicato',
    },
    rebalancedRegularly: {
      en: 'Rebalanced regularly',
      it: 'Riequilibrato regolarmente',
    },
  },
  breakdown: {
    en: 'See pricing breakdown',
    it: 'Vedi ripartizione dei prezzi',
  },
  annualFee: {
    en: 'Annual',
    it: 'Annuali',
  },
  monthlyCost: {
    en: 'Monthly',
    it: 'Mensili',
  },
  moneyFarmFee: {
    en: 'Moneyfarm fee',
    it: 'Costo Moneyfarm',
  },
  underlyingFundFee: {
    en: 'Underlying fund fee',
    it: 'Costo dei Fondi',
  },
  marketSpread: {
    en: 'Market spread',
    it: 'Spread Denaro-Lettera',
  },
  costTotal: {
    en: 'Total',
    it: 'Costo totale',
  },
  moneyFarmFeeInfo: {
    en: 'These ongoing charges include investment management, custody, trading and the provision of digital investment advice, human guidance and support.',
    it: 'I costi ricorrenti includono le commissioni di gestione (per gli advisory consulenza trasmissione e ricezione ordini), custodia, negoziazione, supporto e guida di un consulente dedicato.',
  },
  underlyingFundFeeInfo: {
    en: 'Moneyfarm typically invests in Exchange Traded Funds (ETFs), which carry their own ongoing charges. These are the fees charged by the providers of the ETFs in your portfolio and are built into the price. Also known as total expense ratio, you can find details of these fees  in the Key Investor Information Documents (KIIDS) of the ETFs we invest in. ',
    it: 'Il Total Expense Ratio esprime il costo medio dei fondi sottostanti i portafogli.',
  },
  marketSpreadInfo: {
    en: 'Also known as the transaction cost, this is the difference between what somebody would like to sell an asset for and what somebody is prepared to pay for it. This can act like a cost on your investment when you trade.',
    it: 'Differenziale tra prezzo in acquisto e in vendita di un qualsiasi strumento finanziario quotato. Stime basate sul 2019.',
  },
  suggestedAmount: {
    en: 'Suggested amount',
    it: 'Importo suggerito',
  },
  amountGroupBelow: {
    en: 'Below 100k',
    it: 'Sotto 100k',
  },
  amountGroupAbove: {
    en: 'Above 100k',
    it: 'Sopra 100k',
  },
}

export const error404 = {
  documentTitle: {
    it: 'ERROR 404 - Pagina non trovata!',
    en: 'ERROR 404 - Page not found!',
  },
  bodyTitle: {
    it: '404 - Pagina non trovata!',
    en: '404 - Page not found!',
  },
  bodyMessage: {
    it: `Controlla che l'indirizzo inserito sia corretto, oppure torna alla pagina precedente o alla homepage`,
    en: `Check that you typed the address correctly, go back to your previous page or back to homepage`,
  },
  primaryCTA: {
    it: 'Torna alla homepage',
    en: 'Back to homepage',
  },
  secondaryCTA: {
    it: 'Visita la pagina di aiuto',
    en: 'Visit our help center',
  },
  secondaryCTAURL: {
    it: '/aiuto',
    en: '/help',
  },
}

export const pricingCardsList = {
  belowTitle: {
    it: 'Sotto 100k',
    en: 'Below 100k',
  },
  aboveTitle: {
    it: 'Sopra 100k',
    en: 'Above 100k',
  },
  from: {
    it: 'Da',
    en: 'From',
  },
}

export const investmentAllocationSelection = {
  managedTitle: {
    en: `What is an actively managed portfolio?`,
    it: `Gestione attiva del portafoglio: come funziona?`,
  },
  managedDescription: {
    en: `Here, our experts will keep a close eye on your portfolio and rebalance and adjust it as and when required to reflect the market developments or take advantage new investment opportunities.`,
    it: `In questo caso, i nostri esperti monitorano attivamente il tuo portafoglio e, quando necessario, faranno dei ribilanciamenti per adattarlo agli andamenti di mercato e per farti cogliere nuove opportunità di investimento.`,
  },
  fixedTitle: {
    en: `What is a fixed allocation portfolio?`,
    it: `Gestione passiva del portafoglio: come funziona?`,
  },
  fixedDescription: {
    en: `In this case, our experts will not actively manage your portfolio. They will rebalance your portfolio only once a year. Hence, there is a possibility that your portfolio may not reflect important market events, and you may miss out on attractive investment opportunities.`,
    it: `In questo caso, i nostri esperti non gestiranno attivamente il tuo portafoglio. Faranno solo un ribilanciamento all'anno, quindi è possibile che il tuo portafoglio non si adatti a importanti cambiamenti che si verificano nei mercati, e che tu ti possa perdere qualche opportunità di investimento.`,
  },
  alert: {
    en: `Actively managed portfolios can be slightly more expensive. However, it allows you to enjoy a good night's sleep knowing that a team of experts are keeping an eagle eye on your portfolio all the time to identify any opportunities or risks on the horizon.`,
    it: `I portafogli gestiti attivamente possono risultare meno economici, ma ti permettono di dormire sonni tranquilli mentre un team di esperti si prende cura dei tuoi investimenti, individuando al posto tuo eventuali opportunità e rischi.`,
  },
  closeModal: {
    en: `Close`,
    it: `Chiudi`,
  },
  activelyManaged: {
    en: `Actively managed`,
    it: `Gestione attiva`,
  },
}

export const contacts = {
  phone: {
    en: '0800 433 4574',
    it: '02 450 76621',
  },
  email: {
    en: '/help/#contacts',
    it: '/aiuto/#contatti',
  },
}

export const navigationMenu = {
  callUs: {
    en: 'Call us',
    it: 'Chiamaci',
  },
  emailUs: {
    en: 'Email us',
    it: 'Scrivici',
  },
}

export const blogEntries = {
  readMore: {
    en: 'Read more',
    it: 'Scopri di più',
  },
}

export const multiProductPricingCalculator = {
  gestioneSeparataFees: {
    en: 'Separate management',
    it: 'Gestione Separata',
  },
  unitLinkedFees: {
    en: 'Unit Linked',
    it: 'Unit Linked',
  },
  investmentProduct: {
    en: 'Investment product',
    it: 'Prodotto',
  },
  managementStyle: {
    en: 'Management style',
    it: 'Stile di gestione',
  },
  investmentLine: {
    en: 'Investment line',
    it: 'Linea di investimento',
  },
  focus: {
    en: 'Focus',
    it: 'Focus',
  },
  amountToInvest: {
    en: 'Amount to invest',
    it: 'Importo da investire',
  },
  thematicInvestmentOption: {
    en: 'Thematic investment option',
    it: 'Investimenti tematici',
  },
  focuses: {
    classic: {
      en: 'Classic',
      it: 'Classico',
    },
    esg: {
      en: 'ESG',
      it: 'ESG',
    },
  },
  strategies: {
    active: {
      en: 'Active',
      it: 'Gestione attiva',
    },
    fixed: {
      en: 'Fixed',
      it: '',
    },
    'liquidity-plus': {
      en: 'Liquidity+',
      it: 'Liquidità+',
    },
    'linea-a': {
      en: 'Linea A',
      it: 'Linea A',
    },
    'linea-b': {
      en: 'Linea B',
      it: 'Linea B',
    },
    'linea-c': {
      en: 'Linea C',
      it: 'Linea C',
    },
    'linea-d': {
      en: 'Linea D',
      it: 'Linea D',
    },
    'linea-e': {
      en: 'Linea E',
      it: 'Linea E',
    },
    'sicura-100': {
      en: 'Sicura100',
      it: 'Sicura100',
    },
  },
  products: {
    gia: {
      en: 'General investment',
      it: 'Gestione patrimoniale',
    },
    isa: {
      en: 'ISA',
      it: 'ISA',
    },
    sipp: {
      en: 'Pension',
      it: '',
    },
    jisa: {
      en: 'Junior ISA',
      it: '',
    },
    pip: {
      en: '',
      it: 'Piano pensione',
    },
    'sicura-e-dinamica': {
      en: 'Sicura&Dinamica',
      it: 'Sicura&Dinamica',
    },
    'sicura-100': {
      en: 'Sicura100',
      it: 'Sicura100',
    },
  },
  results: {
    title: {
      en: 'What you’ll pay',
      it: 'Quanto pagherai',
    },
    yearly: {
      en: 'Yearly (%)',
      it: 'Annuale (%)',
    },
    monthly: {
      en: 'Monthly (£)',
      it: 'Mensile (€)',
    },
    total: {
      pipDisclaimer: {
        en: '-',
        it: '+10 €/anno per costi amministrativi',
      },
    },
    pipAdministrativeCosts: {
      en: '',
      it: 'Costi amministrativi',
    },
  },
  managementFees: {
    title: {
      en: 'Our management fees',
      it: 'Commissioni di gestione',
    },
    lesserFeesTip: {
      en: 'As your wealth grows, the Moneyfarm fee can reduce to {amount}.',
      it: 'Riduci le commissioni di gestione fino al {amount}.',
    },
    lesserFeesLink: {
      en: 'More on fees',
      it: 'Scopri di più',
    },
  },
  financialInstrumentsFees: {
    title: {
      en: 'Financial instruments fees',
      it: 'Costi relativi agli strumenti finanziari',
    },
  },
  totalFees: {
    title: {
      en: 'Total estimated costs',
      it: 'Costi totali stimati',
    },
    subtitle: {
      en: 'on all your actively managed investments',
      it: 'todo',
    },
  },
  investmentFundFees: {
    en: 'Underlying fund fees',
    it: 'Commissioni del fondo',
  },
  marketSpreadFees: {
    en: 'Market spread',
    it: 'Market spread',
  },
  marketSpreadFeesTooltip: {
    en: "The market spread effect also impacts your investments. This is a characteristic of trading on the financial markets and represents the difference between bid and ask (buying and selling) prices for an investment at a specific time. This can be 0.02% at Moneyfarm (assuming the average between our Classic and ESG allocations, we sell 20% of the assets in your portfolio and replace them with new assets during the year), but you'll always see the real price you buy and sell assets at.",
    it: "L'effetto dello spread di mercato influisce anche sui tuoi investimenti. Questa è una caratteristica del trading sui mercati finanziari e rappresenta la differenza tra i prezzi di acquisto e vendita per un investimento in un determinato momento. Questo può essere 0.02% su Moneyfarm (supponendo la media tra le nostre allocazioni ESG, vendiamo il 40% degli asset nel tuo portafoglio e li sostituiamo con nuovi asset durante l'anno), ma vedrai sempre il prezzo reale a cui compri e vendi asset.",
  },
  footer: {
    title: {
      en: 'You won’t pay',
      it: 'Nessun costo:',
    },
    list: {
      1: { en: 'Entry and exit fees', it: 'di entrata e uscita' },
      2: { en: 'Withdrawal', it: 'sui versamenti' },
      3: { en: 'Top-ups', it: 'di custodia' },
      4: { en: 'Custody fee', it: 'sulle performance' },
    },
  },
  generic: {
    upTo: {
      en: 'up to',
      it: 'fino a',
    },
    free: {
      en: 'Free',
      it: 'Gratis',
    },
    from: {
      en: 'From',
      it: 'Da',
    },
    notApplicable: {
      en: 'N.A.',
      it: 'N.A.',
    },
  },
  'pip-additionalFees': {
    title: {
      en: '',
      it: 'Costi di amministrazione',
    },
  },
  feesModal: {
    title: {
      en: 'Fees breakdown',
      it: 'Dettaglio costi',
    },
    managementFees: {
      sectionTitle: {
        en: 'Ongoing charges',
        it: 'Spese ricorrenti',
      },
      tableTitle: {
        en: 'Moneyfarm management fee',
        it: 'Spese di gestione Moneyfarm',
      },
      sicuraEDinamicaDisclaimer: {
        en: '',
        it: 'Per la quota parte del capitale investito nella componente unit linked è previsto, in aggiunta al costo di gestione, un costo medio dei fondi di circa 0,18% e bid-ask spread annuale in media dello 0,08%.',
      },
    },
    investmentFundFees: {
      sectionTitle: {
        en: 'Instruments costs',
        it: 'Costi degli strumenti',
      },
      tableTitle: {
        en: 'Fund fees',
        it: 'Costo dei fondi',
      },
      esg: {
        en: 'ESG',
        it: 'ESG',
      },
      active: {
        en: 'Active management',
        it: 'Classici',
      },
      thematics: {
        en: 'Thematic investments',
        it: 'Investimenti tematici',
      },
    },
    oneOffFees: {
      tableTitle: {
        en: 'One off charges',
        it: 'Spese una tantum',
      },
      content: {
        en: 'Such as entry, deposit, or termination fee, withdrawal or switching costs.',
        it: 'Come costi di ingresso, di deposito o penale di terminazione, costi di prelievo o di cambio.',
      },
    },
    ancillaryServicesFees: {
      tableTitle: {
        en: 'Ancilliary services',
        it: 'Costi per servizi accessori',
      },
      content: {
        en: 'Ancillary services include investment help and guidance form our experts, ongoing analysis and adjustments and the access to our platform.',
        it: "Nei servizi accessori sono inclusi l’assistenza e consulenza per gli investimenti da parte dei nostri esperti, analisi e aggiustamenti continui e l'accesso alla nostra piattaforma.",
      },
    },
    incidentalCosts: {
      tableTitle: {
        en: 'Incidental costs',
        it: 'Costi accessori',
      },
      content: {
        en: 'Incidental expenses are minor, non-budgeted expenses that are not directly related to primary service such as performance fees, which we don’t have.',
        it: 'Le spese occasionali sono spese minori e non preventivate che non sono direttamente correlate al servizio principale, come ad esempio le commissioni di performance, che non applichiamo.',
      },
    },
    marketSpreadFees: {
      sectionTitle: {
        en: 'Transaction costs',
        it: 'Costi di transazione',
      },
      tableTitle: {
        en: 'Market spread',
        it: 'Market spread',
      },
    },
  },
  marketSpreadModal: {
    title: {
      en: 'Market spread explained',
      it: 'Market spread spiegato',
    },
  },
}

export const generic = {
  close: {
    en: 'Close',
    it: 'Chiudi',
  },
  year: {
    en: 'year',
    it: 'anno',
  },
  total: {
    en: 'Total',
    it: 'Totale',
  },
}

 export const cookies = {
   consent: {
     compact: {
       title: {
         en: 'We use cookies',
         it: 'Il nostro sito utilizza i cookie',
       },
       description: {
         en: 'Cookies help us provide you with the best experience on our website. By accepting cookies, you allow us to use them as per the [Cookie Policy](https://www.moneyfarm.com/uk/cookies-policy/). You can also change your preferences at any time.',
         it: 'I cookie ci aiutano a offrirti la migliore esperienza sul nostro sito. Accettando i cookie ci permetti di usarli come indicato nella nostra [Cookie Policy](https://www.moneyfarm.com/it/cookie-policy/). Puoi gestire le tue preferenze in qualsiasi momento.',
       },
       cta: {
         secondary: {
           en: 'Manage preferences',
           it: 'Gestisci preferenze',
         },
         primary: {
           en: 'OK, I agree',
           it: 'Ok, accetto',
         },
       },
     },
     detail: {
       link: {
         back: {
           en: 'Back',
           it: 'Indietro',
         },
       },
       title: {
         en: 'About cookies on this site',
         it: 'Informazioni sui cookie su questo sito',
       },
       description: {
         en: "We use different cookies to improve your experience on our site. We use some necessary cookies to make our website function properly, and you can't disable them. For others, you can allow or turn them off depending on your preferences. Once you disable any cookie category, we'll remove all cookies assigned to it from your browser. Dive deeper into how we use cookies by clicking on 'Learn more'.\n\n[Learn more](https://www.moneyfarm.com/uk/cookies-policy/)",
         it: 'I cookie utilizzati nel sito sono diversi: qui sotto puoi leggere quali usiamo e accettarne o negarne alcuni (o tutti). Se le categorie che erano state precedentemente consentite vengono disabilitate, tutti i cookie assegnati a quella categoria verranno rimossi dal tuo browser. Puoi trovare un elenco di cookie assegnati a ciascuna categoria e informazioni più dettagliate nella dichiarazione dei cookie.\n\n[Scopri di più](https://www.moneyfarm.com/it/cookie-policy/)',
       },
       cta: {
         secondary: {
           en: 'Save settings',
           it: 'Salva preferenze',
         },
         primary: {
           en: 'Allow all',
           it: 'Consenti tutti',
         },
       },
     },
     preferences: {
       title: {
         en: 'Preferences',
         it: 'Preferenze',
       },
     },
     option: {
       mandatory: {
         label: {
           en: 'Necessary cookies ',
           it: 'Cookie necessari ',
         },
         'label.note': {
           en: '(you can’t disable them)',
           it: '(obbligatori)',
         },
         description: {
           en: "Some cookies provide core functionality, and the website won't function as intended without these cookies. These cookies are enabled by default, and you can't turn them off.",
           it: 'Questi cookie sono necessari per fornire funzionalità di base. Il sito web non funzionerà correttamente senza di essi e sono abilitati per impostazione predefinita, quindi non possono essere disabilitati.',
         },
       },
       consent_ad_storage: {
         label: {
           en: 'Advertising',
           it: 'Marketing',
         },
         description: {
           en: 'They enable us to tailor adverts and measure the effectiveness of advertising on our site. For example, we may present a personalised ad, depending on your browsing history.',
           it: "Questi cookie servono a personalizzare e misurare l'efficacia della pubblicità sul nostro sito. Ad esempio, potremmo offrirti un annuncio personalizzato in base alle pagine che visiti sul nostro sito.",
         },
       },
       consent_analytics_storage: {
         label: {
           en: 'Analytics',
           it: 'Statistiche',
         },
         description: {
           en: 'These cookies help us monitor our website performance and enhance your browsing experience. For example, the data helps us improve our services and your interactions on our site.',
           it: "Questi cookie monitorano il tuo comportamento sul sito per fornirti un'esperienza di navigazione più pertinente o personalizzare i contenuti. Ad esempio, raccogliamo informazioni su quali pagine visiti per aiutarci a presentarti informazioni più rilevanti.",
         },
       },
       consent_personalization_storage: {
         label: {
           en: 'Personalisation',
           it: 'Personalizzazioni',
         },
         description: {
           en: 'They allow us to understand your behaviour and personalise the content you see on our site. For example, we can show content relevant to you, depending on your settings.',
           it: 'Questi cookie servono a monitorare le prestazioni del nostro sito web e migliorare la tua esperienza di navigazione. Ad esempio, questi strumenti ti consentono di comunicare con noi tramite chat dal vivo.',
         },
       },
     },
   },
 }

 export const glossaryBanner = {
   title: {
     en: '',
     it: 'Facciamo il primo passo.',
   },
   description: {
     en: '',
     it: 'Moneyfarm è una piattaforma di investimento digitale che offre un’ampia gamma di servizi per aiutarti a investire in maniera efficiente, trasparente e allineata ai tuoi valori.',
   },
   cta: {
     label: {
       en: '',
       it: 'Inizia ora',
     },
     target: {
       en: '',
       it: 'https://app.moneyfarm.com/it/sign-up',
     },
   },
   disclaimer: {
     en: '',
     it: 'Capitale a rischio.',
   },
 }
